<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>播放记录</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期范围 -->
    <span class="tool-title">日期范围：</span>
    <a-date-picker
      v-model:value="dateRangeModel"
      picker="month"
      :allow-clear="false"
      :disabled-date="disabledDate"
    />
    <!-- UID -->
    <span class="tool-title">UID：</span>
    <a-input
      v-model:value="searchModel"
      placeholder="请输入"
      style="width: 200px;"
    />
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="touchSearch">查询</a-button>
    <a-button style="margin-left: 16px; margin-right: 16px;" @click="touchReset">重置</a-button>
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button @click="touchDownload">导出数据</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.created_at"
    :pagination="false"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <!-- <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'total_billing_gmv' || column.key === 'total_billing_refund_gmv' || column.key === 'total_ad_share_money'">
        {{ record[column.key] === '-' ? '-' : $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
    </template> -->
  </a-table>
  <!-- 自定义分页器 -->
  <a-pagination
    v-if="dataSource.length"
    style="margin: 16px 0; text-align: right;"
    v-model:current="pagination.current"
    v-model:pageSize="pagination.pageSize"
    :total="pagination.total"
    :showSizeChanger="pagination.showSizeChanger"
    @change="handlePaginationChange"
  />
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import * as Ex from "@netjoy/excelex"
import Pub from '@/utils/public'
import dayjs from 'dayjs'
import { videoPlayRecordList } from '@/api/copyright'

// 加载
let isLoading = ref(false)
// 项目
let searchModel = ref(undefined)
let search = ref(undefined)
// 日期范围
const dateFormat = 'YYYY_MM'
let dateRangeModel = ref(dayjs().endOf('month'))
let dateRange = ref(dayjs().endOf('month'))
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '播放时间',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    key: 'user_id'
  },
  {
    title: '平台',
    dataIndex: 'platform_name',
    key: 'platform_name'
  },
  {
    title: '播放内容',
    dataIndex: 'name',
    key: 'name'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取推广数据列表
  // getList()
})

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 分页处理
function handlePaginationChange (current, pageSize) {
  pagination.current = current
  pagination.pageSize = pageSize
  getList()
}

// 禁止月份
function disabledDate (current) {
  return current && (current > dayjs().endOf('month') || current < dayjs().subtract(12, 'months').endOf('month'))
}

// 查询
function touchSearch () {
  // 调整参数
  search.value = searchModel.value
  dateRange.value = dateRangeModel.value
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function touchReset () {
  dateRange.value = dayjs().endOf('month')
  dateRangeModel.value = dayjs().endOf('month')
  search.value = undefined
  searchModel.value = undefined
  // 初始化获取
  touchSearch()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    ym: dateRange.value && dateRange.value.format(dateFormat),
    uid: search.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  videoPlayRecordList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    ym: dateRange.value && dateRange.value.format(dateFormat),
    uid: search.value,
    page: 1,
    page_size: 100000
  }
  videoPlayRecordList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      // 将汇总数据插入第一条
      const list = data.data
      if (list.length) {
        const columns = [
          {
            name: '播放时间',
            field: 'created_at'
          },
          {
            name: 'UID',
            field: 'user_id'
          },
          {
            name: '平台',
            field: 'platform_name'
          },
          {
            name: '播放内容',
            field: 'name'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            // 金额处理
            // if (field === 'total_billing_gmv' || field === 'total_billing_refund_gmv' || field === 'total_ad_share_money') {
            //   if (item.data != '-') {
            //     item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
            //   }
            //   return item
            // }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `运营管理-播放记录`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>